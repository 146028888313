import request from '@/utils/request'


export function saveDirectory(data) {
    return request.post('/diary/notes/saveDirectory', data)
}
export function deleteDirectory(data) {
    return request.get('/diary/notes/deleteDirectory', { params: data })
}
export function listNotes(data) {
    return request.get('/diary/notes/listNotes', { params: data })
}
export function saveNote(data) {
    return request.post('/diary/notes/addNote', data)
}
export function deleteNote(data) {
    return request.get('/diary/notes/deleteNote', { params: data })
}
export function modifyNote(data) {
    return request.post('/diary/notes/modifyNote', data)
}
export function modifyNoteDirectory(data) {
    return request.post('/diary/notes/modifyNoteDirectory', data)
}
export function noteById(data){
    return request.get('/diary/notes/getNoteById', { params: data })
}
export function batchImportNotes(files, directoryId) {
    const formData = new FormData();
    
    // 检查 files 是否是一个数组，并将每个文件添加到 FormData 中
    if (Array.isArray(files)) {
        files.forEach(file => {
            formData.append('files', file);
        });
    } else {
        // 如果 files 不是数组，则直接添加
        formData.append('files', files);
    }

    formData.append('directoryId', directoryId);

    return request.post('/diary/notes/batchImportNotes', formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
}
export function buildShareKey(data){
    return request.get('/diary/notes/buildShareSecret', { params: data })
}
export function getShareNote(data){
    return request.get('/diary/notes/getShareNote', { params: data })
}